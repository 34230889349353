document.onscroll = function () { stickyMenuFn() };

document.getElementById("menuBurger").addEventListener("click", burgerAnimateFn);
document.getElementById("btnsideFormFooter").addEventListener("click", sideFormFn);
document.getElementById("btnsideFormNav").addEventListener("click", sideFormFn);
document.getElementById("btnsideFormClose").addEventListener("click", sideFormFn);
document.getElementById("btnsideFormCloseSuccess").addEventListener("click", sideFormFn);
document.getElementById("btnsideFormCancel").addEventListener("click", sideFormFn);
document.getElementById("btnDownloadKit").addEventListener("click", scrollToMidiaKit);



document.getElementById("contactMobile").addEventListener("click",sideFormFn);

var form = document.getElementById('form-midiakit');
if (form.attachEvent) {
    form.attachEvent("submit", submitFormMediakit);
} else {
    form.addEventListener("submit", submitFormMediakit);
}

var form = document.getElementById('form-contact');
if (form.attachEvent) {
    form.attachEvent("submit", submitFormContact);
} else {
    form.addEventListener("submit", submitFormContact);
}

window.addEventListener('load', function() {
    var local = window.location.pathname.split('/');
    for (var i = 0; i < local.length; i++) {
        if (local[i] === 'index.html' || local[i+1] === '')
            document.getElementById('oiAds').classList.add('active');
        else if (local[i] === 'tecnologia.html')
            document.getElementById('Tecnologia').classList.add('active');
        else if (local[i] === 'midia.html')
            document.getElementById('Midia').classList.add('active');
    }
})



function stickyMenuFn() {
    var header = document.getElementById("NavTop");
    var sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}

function burgerAnimateFn() {
    var show = document.getElementById("Menu");
    if (show.style.display === "flex")
        show.style.display = "none";
    else
        show.style.display = "flex";
}


function sideFormFn() {
    var side = document.getElementById("sideForm");
    if (side.style.display === "flex"){
        side.style.display = "none";
    } else {
        side.style.display = "flex";
        var formContact = document.getElementById("form-contact");
        formContact.style.display = "flex";
        var baseSuccess = document.getElementById("base-success-contact");
        baseSuccess.style.display = "none";
    }
}

function scrollToMidiaKit() {
    document.getElementById('midiaKitForm').scrollIntoView({
        behavior: 'smooth'
    });
}

function submitFormMediakit(e) {
    if (e.preventDefault) e.preventDefault();
    
    window.dataLayer.push({'event': 'send-lead-download-mediakit'});

    var name = document.getElementById("name-form-midiakit");
    var email = document.getElementById("email-form-midiakit");

    sendApi({
        type: "lead",
        body: {
            name: name.value,
            email: email.value
        }
    }, function(success) {
        if(success){
            name.value = '';
            email.value = '';
            window.open('./assets/docs/Oi-ADS-midia-kit.pdf', '_blank');
        }
    })

    return false;
}

function submitFormContact(e) {
    if (e.preventDefault) e.preventDefault();
    
    window.dataLayer.push({'event': 'send-form-contact'});

    var name = document.getElementById("name-form-contact");
    var email = document.getElementById("email-form-contact");
    var investiment = document.getElementById("investiment-form-contact");
    var phone = document.getElementById("phone-form-contact");
    var company = document.getElementById("company-form-contact");
    var office = document.getElementById("office-form-contact");
    var commentaries = document.getElementById("commentaries-form-contact");

    sendApi({
        type: "contact",
        body: {
            name: name.value,
            email: email.value,
            investiment: investiment.value, 
            phone: phone.value, 
            company: company.value, 
            office: office.value, 
            commentaries: commentaries.value
        }
    }, function(success) {
        if(success){
            var formContact = document.getElementById("form-contact");
            formContact.style.display = "none";
            var baseSuccess = document.getElementById("base-success-contact");
            baseSuccess.style.display = "flex";

            name.value = '';
            email.value = '';
            investiment.value = '';
            phone.value = '';
            company.value = '';
            office.value = '';
            commentaries.value = '';
        } else {
            
        }
    });

    return false;
}

function sendApi(data, cb){
    var xhr = new XMLHttpRequest();
    var url = "https://jd5gecifq6.execute-api.us-east-1.amazonaws.com/prod/oiads/contact";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = function () { 
        if (xhr.readyState == 4 && xhr.status == 200) {
            cb(true);
        } else {
            cb(false);
        }
    }
    var body = JSON.stringify(data);
    xhr.send(body);
}

